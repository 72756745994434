import ApiService from "./ApiService";

export const getBaseCalculadora = async (siglaEstado) => {
    const { data } = await ApiService.get(`produtosNcm/${siglaEstado}`);
    return data;
};

export const getReferenciaNCM = async () => {
    const { data } = await ApiService.get(`baseProdutosNcm`);
    return data;
};

export const getReferenciaPercentualNCM = async () => {
    const { data } = await ApiService.get(`basePercentualProdutosNcm`);
    return data;
};

export const getReferenciaExcecaoPercentualNCM = async () => {
    const { data } = await ApiService.get(`baseExcecaoPercentualProdutosNcm`);
    return data;
};

export const exportarPDFCalculadora = async (postData) => {
    const { data } = await ApiService.post(`pdf/calculadora`, postData);
    return data;
};