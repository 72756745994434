
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue';
import { exportarPDFCalculadora, getBaseCalculadora } from '@/services/CalculadoraPrecosService';
import { hideModal } from '@/core/helpers/dom';
import { config } from './ConfigNcm';
import ApexChart from "apexcharts";
import {VMoney} from 'v-money';
import usePDF from '@/composables/Pdf'
import { Modal } from "bootstrap";
import store from "@/store/index";
import useAlert from "@/composables/Alert";
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: "CalculadoraPrecos",
  components: {
  },

  directives: {money: VMoney, percent: VMoney},

  setup() {
    const { exportBlob } = usePDF();
		const { showTimeAlert } = useAlert();
    const router = useRouter();

    function goTo(route){       
      router.push(route);
    }

    function calculaValoresTodosProdutos(){
      produtosList.value.forEach(produto => {
        calculaValoresProduto(produto);
      });
    };

    function resizeWithTime(time = 100){
      setTimeout(() => window.dispatchEvent(new Event('resize')), time)
    }

    const money = {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
    }

    const percent = {
      decimal: ',',
      thousands: '',
      prefix: '',
      suffix: '%',
      precision: 2,
    }

    function createChartOptions(valorPrincipal){
      let color;

      if(valorPrincipal > 30){
        color = "#28A745"
      } else if (valorPrincipal > 20) {
        color = "#FFB822"
      } else {
        color = "#F23F44";
      }

      const optionsChart = {
        chart: {
          width: '366',
          type: 'radialBar',
          offsetY: 5,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '58%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front'
            },
            track: {
              strokeWidth: '67%',
              margin: 0        
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '10px',
                color: 'var(--cor_texto_2)'
              },
              
              value: {
                offsetY: -40,
                fontSize: '22px',
                fontFamily: 'Poopins', 
                color,
                fontWeight: 'bold',
                formatter: function (val) {
                  let valor = valorPrincipal && !isNaN(valorPrincipal)? valorPrincipal : 0;
                  valor = valor.toFixed(1);
                  return `${valor}%`;
                }
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        stroke: {
          lineCap: 'round',
          width: 10,
        },
        fill:{
          type: 'image',
          image: {
            src: ['bg/bg-graph.jpeg'],
            width: 275,
            height: 155,
          },
          // type: 'gradient',
          // gradient: {
          //   type: 'diagonal1',
          //   colorStops: [
          //     {
          //       offset: 0,
          //       color: "#F23F44",
          //       opacity: 1
          //     },
          //     {
          //       offset: 15,
          //       color: "#FFB822",
          //       opacity: 1
          //     },
          //     {
          //       offset: 60,
          //       color: "#28A745",
          //       opacity: 1
          //     }
          //   ]
          // }
        },
        labels: [''],
      };
      return optionsChart;
    }

    const optionsChart = createChartOptions(0);
    let chartInstance;

    const scrollPosition = ref(0);
    const refModal = ref();

    async function exportarPDF(){
      loadingExport.value = true;
      const valorPrincipal = mainValor.value;
      let valorFormatted = valorPrincipal && !isNaN(valorPrincipal)? valorPrincipal : 0;
      valorFormatted = valorFormatted.toFixed(1);

      const uf = ufOptions.find(uf => uf.value === ufSelected.value)?.text;

      const produtos = produtosList.value.map(produto => {
        return {
          nome: produto.PRODUTO,
          estoque: produto["QTD FISICO"] ? produto["QTD FISICO"] : 0,
          codigo: produto.CODIGO,
          quantidade: produto.quantidade ? produto.quantidade : 0,
          precoProposto: produto.precoProposto ? produto.precoProposto : "R$ 0,00",
          tampa: produto.tampa ? produto.tampa : "R$ 0,00",
          fi: produto.fi ? produto.fi : "0,00%",
          ir: produto.irFormated ? produto.irFormated : "0%",
        }
      })

      let postData:any = {
        config: {
          percentualPrincipal: valorFormatted,
          uf, 
          prazo: prazo.value
        },
        distribuidor: store.getters.layoutConfig("distribuidor.distribuidor"),
        produtos
      }

      const response = await exportarPDFCalculadora(postData);

      exportBlob(response, "CalculadoraPrecos", true);

      loadingExport.value = false;
    }

    function updateScroll(){
      scrollPosition.value = window.scrollY;
    }

    onMounted(async () => {
      const element = document.getElementById("modal-add-produto");
      refModal.value = new Modal(element);
      window.addEventListener('scroll', updateScroll);
      chartInstance = new ApexChart(document.querySelector("#chart"), {
        ...optionsChart,
        series: ["100"],
      });
      chartInstance.render();
      styleMark.value = "transform: rotate(deg(0))";

      resizeWithTime();
      // await listaBaseCalculadora("");
    });
    
    const loading = ref(false);
    const loadingExport = ref(false);
    const produtoOptionsInitial:any = ref([]);
    const produtoOptions:any = ref([]);
    const produtosSelected:any = ref([]);
    const produtosList:any = ref([]);

    const prazo = ref();
    const ufSelected = ref("");
    
    function removeProduto(indexProduto){            
      produtosList.value.splice(indexProduto, 1);
      produtoOptions.value = produtoOptionsInitial.value.filter(produto => !produtosList.value.some(produtoLista => produtoLista.CODIGO == produto.value.CODIGO));

      const percentualMedio = calculaPercentualMedio();
      calculaPosition(percentualMedio);
    }

    function addProdutos(){
      const produtosToAdd = [...produtosSelected.value];

      produtosToAdd.forEach(produto => {
        // const refExcecao = config.excecoesNcm.find(excecao => produto.codigo == excecao.codigoProduto && produto.CODIGO_NCM == excecao.codigo);
        // if(refExcecao){
        //   produto.percentualNSM = refExcecao.percentualEstados[ufSelected.value];
        // } else {
        //   const refNcm = config.ncm.find(ncm => produto.CODIGO_NCM == ncm.codigo);

        //   if(refNcm){
        //     produto.percentualNSM = refNcm.percentualEstados[ufSelected.value];
        //   } else {
        //     produto.percentualNSM = "0";
        //   }
        // };

        produto.initialPercentualNSM = produto.percentualNSM;
        produto.percentualNSM = produto.percentualNSM ?? "0";
        produto.quantidade = produto.quantidade ?? "";
        produto.precoProposto = produto.precoProposto ?? "";
        produto.tampa = produto.tampa ?? "";
        produto.fi = produto.fi ?? "";
        produto.irValor = produto.irValor ?? "";
        produto.ir = produto.ir ?? "";
        produto.irFormated = produto.irFormated ?? "0%";
        const custoFormatado = produto.CUSTO ? Number(produto.CUSTO).toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2}) : "";
        produto.custoFormatado = custoFormatado;
        produto.pisConfins = produto.pisConfins ?? "";
        produto.icms = produto.icms ?? "";
        produto.custoPrazo = produto.custoPrazo ?? "";
      })

      produtosList.value.push(...produtosToAdd);
      produtoOptions.value = produtoOptionsInitial.value.filter(produto => !produtosSelected.value.some(produtoSelecionado => produtoSelecionado.CODIGO == produto.value.CODIGO));
      produtosSelected.value = [];

      refModal.value.hide();
    };

    async function listaBaseCalculadora(siglaEstado){
      loading.value = true;
      produtosList.value = [];
      produtosSelected.value = [];

      calculaPosition(0);
      
      try {
        const response = await getBaseCalculadora(siglaEstado);

        if(response){
          const responseOrdenada = response.sort((a, b) => {
            if (a.CODIGO_NCM && !b.CODIGO_NCM) {
              return -1;
            }

            if(!a.CODIGO_NCM && b.CODIGO_NCM) {
              return 1;
            }
            
            if(a.PRODUTO < b.PRODUTO){
              return -1;
            }

            if(b.PRODUTO < a.PRODUTO){
              return 1;
            }

            return 0;
          })

          const options = responseOrdenada.map(produto => {
            return {
              text: produto.PRODUTO,
              value: produto,
              disabled: !produto.CODIGO_NCM
            }
          });
          produtoOptionsInitial.value = [...options];
          produtoOptions.value = [...options];
        };
      } catch (e) {
        produtoOptionsInitial.value = [];
        produtoOptions.value = [];
        showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loading.value = false;
      }
    };

    const bgColorIndicador = ref("bg-danger");

    function calculaValoresProduto(produto){
      const precoProposto = produto.precoProposto.replace("R$ ", "").replaceAll(".", "").replace(",", ".");
      const tampa = produto.tampa.replace("R$ ", "").replaceAll(".", "").replace(",", ".");
      const fi = produto.fi.replace(",", ".").slice(0, -1);

      if(produto.quantidade > 0){
        const custoPrazo = calculaCustoPrazo(precoProposto);
        const icms = calculaICMS(precoProposto, produto.percentualNSM);
        const pisCofins = calculaPisCofins(precoProposto, icms);

        let valorIR = calculaValorIRProduto(precoProposto, produto.CUSTO, pisCofins, icms, custoPrazo, tampa, fi);        
        produto.irValor = valorIR;

        const percentualIR = valorIR / precoProposto ? valorIR / precoProposto : 0;
        produto.ir = percentualIR;

        if(percentualIR && isFinite(percentualIR)){
          produto.ir = (percentualIR * 100).toFixed(1);
        } else {
          produto.ir = 0;
        }
        
        produto.irFormated = produto.ir + '%';

        const percentualMedio = calculaPercentualMedio();

        if(percentualMedio >= 30){
          bgColorIndicador.value = 'bg-success';
        } else  if (percentualMedio > 20 && percentualMedio < 30) {
          bgColorIndicador.value = 'bg-warning';
        } else {
          bgColorIndicador.value = 'bg-danger';
        }

        calculaPosition(percentualMedio);
        
        let percentualTratado = percentualMedio && !isNaN(percentualMedio)? percentualMedio : 0;
        percentualExibicao.value = percentualTratado.toFixed(1);

        produto.custoPrazo = custoPrazo;
        produto.icms = icms;
        produto.pisConfins = pisCofins;
      }
    };

    function calculaValorIRProduto(precoProposto, custo, pisCofins, icms, custoPrazo, tampa, fi) {
      const calculoTampa = (tampa * 1.05) ? (tampa * 1.05) : 0;
      const calculoPrecoProposto = (precoProposto * 14 / 100) ? (precoProposto * 14 / 100) : 0;
      const calculoPrecoPropostoFI = (precoProposto * fi / 100) ? (precoProposto * fi / 100) : 0;
      const valorIR = precoProposto - custo - pisCofins - icms - custoPrazo - calculoTampa - calculoPrecoProposto - calculoPrecoPropostoFI;
      return valorIR;
    };

    function calculaICMS(precoProposto, percentualNCM = 0){
      return percentualNCM ? ((precoProposto * percentualNCM) / 100) : 0;
    };

    function calculaPisCofins(precoProposto, icms){
      const aliquotaMaxima = 9.25 / 100;
      return aliquotaMaxima * (precoProposto - icms);
    };

    function calculaCustoPrazo(precoProposto){
      const taxa = 0.0006677;
      return taxa * precoProposto * prazo.value;
    };

    const styleMark = ref("display: none");
    const percentualExibicao = ref("0.0");
    const mainValor = ref();

    function calculaPosition(valor){
      const newOptions = createChartOptions(valor);
      mainValor.value = valor;
      chartInstance.updateOptions(newOptions);
      calculaMarkPosition(valor);
    }

    function calculaPercentualMedio(){
      let somarProdutoA = 0;
      let somarProdutoB = 0;

      produtosList.value.forEach(produto => {
        const precoProposto = produto.precoProposto.replace("R$ ", "").replaceAll(".", "").replace(",", ".");
        somarProdutoA += produto.quantidade * precoProposto * produto.ir;
        somarProdutoB += produto.quantidade * precoProposto;
      });

      const percentualMedio = somarProdutoA / somarProdutoB;

      return percentualMedio;
    }

    function calculaMarkPosition(valor){
      let deg = 1.8 * valor;

      if(deg < 0)
      deg = 0

      if(deg > 100)
      deg = 100

      styleMark.value = `transform: rotate(${deg}deg)`
    }
    // Declara variável para simular array vazio
  
    const ufOptions = [
      {
        text: "Minas Gerais",
        value: "mg"
      },
      {
        text: "São Paulo",
        value: "sp"
      },
      {
        text: "Rio de Janeiro",
        value: "rj"
      },
      // {
      //   text: "Rio Grande do Sul",
      //   value: "rs"
      // },
      {
        text: "Santa Catarina",
        value: "sc"
      },
      {
        text: "Paraná",
        value: "pr"
      },
    ]

    return { goTo, exportarPDF, bgColorIndicador, scrollPosition, percentualExibicao, money, percent, ufOptions, ufSelected, prazo, produtoOptions, produtosSelected, produtosList, loading, loadingExport, listaBaseCalculadora, addProdutos, calculaValoresProduto, removeProduto, calculaPosition, styleMark, calculaValoresTodosProdutos, refModal }
  }
});
